<template>
  <div class="atuts">
    
          <img src="@/assets/icons/check-circle.svg"><strong>Doświadczenie i Renoma:</strong> <p>Ponad 25 lat na rynku, uznanie wśród pacjentów i lekarzy.</p>
          <img src="@/assets/icons/check-circle.svg"><strong>Zaawansowana Diagnostyka:</strong> <p>Specjalizujemy się w radiologii klasycznej i stomatologicznej.</p>
          <img src="@/assets/icons/check-circle.svg"><strong>Bezpieczeństwo i Jakość:</strong> <p>Nowoczesne aparaty RTG z certyfikatami bezpieczeństwa, regularne przeglądy i testy.</p>
          <img src="@/assets/icons/check-circle.svg"><strong>Partnerstwo:</strong> <p>Od 2003 roku współpracujemy z Pracownią Rentgenowską Stomatologiczną DENTARAMA w Wałbrzychu.</p>
      
  </div>
</template>

<script>

</script>


<style scoped lang="scss">

@import './../main.scss';

.atuts{
  margin: 2rem;
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 1rem auto auto;
  gap: 0rem 2rem;
  grid-template-rows: 5fr 5fr 5fr 5fr
}

strong{
  font-size: 1.6rem;
  color: $main-color-dark;
}


p{
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 4rem;
  margin-bottom: 1.5rem;
}

img{
  margin-top: 0.3rem;
  max-height: 1.6rem; 
}


</style>
