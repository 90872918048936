<template>
  <div class="footer-container">
    <div class="footer">
      <div>
        <p>Pracownia Medyczna MED-X</p>
        <p>ul. Dworcowa 5</p>
        <p>58-100 Świdnica</p>
        <p>tel: 748512787</p>
        <p>Czynne:</p>
        <p>poniedziałek-czwartek: 8:00-18:00</p>
        <p>piątek: 8:00-17:00</p>
      </div>
      <div>
        <p>MED-X Rentgen Stomatologiczny</p>
        <p>Centrum Stomatologiczne Dentarama</p>
        <p>ul. Senatorska 28a</p>
        <p>58-316 Wałbrzych</p>
        <p>tel: 748512787</p>
        <p>Czynne:</p>
        <p>poniedziałek-piątek: 9:30-19:00</p>
      </div>
    </div>
   
      
  </div>
</template>

<script>

</script>


<style scoped lang="scss">

@import './../main.scss';

.footer-container{
  background-color: $secendary-color-light;
  margin-top: 4rem;
  
  
}

.footer{
  padding: 1rem;
  max-width: $page-width;
  margin-left: auto;
  margin-right: auto;
  color: $light-color;
  display: grid;
  grid-template-columns: auto auto;
}



</style>
