<template>
  <div class="home">
    <HeroComponent />
    <AtutsComponen />
  </div>
</template>

<script>
// @ is an alias to /src
import HeroComponent from '@/components/HeroComponent.vue'
import AtutsComponen from '@/components/AtutsComponent.vue'

export default {
  name: 'HomeView',
  components: {
    HeroComponent,
    AtutsComponen
  },
  
}
</script>
