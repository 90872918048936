<template>
  <div class="hero">
    <img src="@/assets/pictures/05.jpg" alt="zdjęcie lekarza oglądającego zdjęcie rendgenowskie">
    <div class="hero-text">
      <h1>Pracownia Medyczna <span class="medx">MED-X</span> </h1>
      <h2>Pierwsza, prywatna pracownia rentgenowska w regionie</h2>

    </div>
  </div>
</template>

<script>
import { useMainStore } from '@/store'

export default {
  setup() {
    const mainStore = useMainStore()

    const changeMessage = () => {
      mainStore.updateMessage('New message from Pinia!')
    }

    return {
      message: mainStore.message,
      changeMessage
    }
  }
}
</script>


<style scoped lang="scss">

@import './../main.scss';
.hero {
  width: 100%;  
  overflow: hidden;
  position: relative;
  height: 40rem; 
  
}

.hero img {
  width: 100%;
  clip-path: inset(0 0 13% 0); 
  transform: scale(1.3); 
  transform-origin: center 70%;
}

.hero::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  pointer-events: none;
}

.hero-text{
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), rgba(194, 193, 193, 0.3);
  padding: 2rem;
  z-index: 1;
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  max-width: 60rem;
  strong, li, p, h2{
    color: $light-color;
  }
  strong{
    font-size: 1.9rem;
    color: $main-color-dark;
  }
  li, p{
    font-size: 1.8rem;
    margin-bottom: 1rem;
    list-style-type: none;

  }
  h1{
    font-size: 3.2rem;
    margin-bottom: 2rem;
    color: $light-color;
  }

  .medx{
    font-size: 3.2rem;
    color: $main-color;
  }
  h2{
    font-size: 2rem;
    margin-bottom: 0rem
  }
  p{
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }

}

</style>
